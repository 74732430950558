import React from "react";

type Props = { children: React.ReactNode };
export class ErrorBoundary extends React.Component<Props, { error?: Error }> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    console.error("Component rendered an error:", error, errorInfo);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Something went wrong. </h2>
          <div>{this.state.error.toString()}</div>
        </div>
      );
    }

    return this.props.children;
  }
}
