import React from "react";

export function Fancy({
  children,
  className,
}: {
  children: string;
  className?: string;
}) {
  const parts = [];
  let accum = [];
  let i = 0;
  for (const letter of children) {
    i++;
    if (letter.toUpperCase() === letter) {
      parts.push(accum);
      parts.push(<em key={i}>{letter}</em>);
      accum = [];
    } else {
      accum.push(letter);
    }
  }
  parts.push(accum);
  accum = [];
  return <div className={`fancy ${className}`}>{parts}</div>;
}

export function Nth({ children }: { children: React.ReactNode }) {
  return (
    <span>
      {children}
      <span className="th">th</span>
    </span>
  );
}

export function HeroImage({
  src,
  className,
}: {
  src: string;
  className?: string;
}) {
  return (
    <div className={`hero-image ${className}`}>
      <img src={src} alt="" />
    </div>
  );
}
