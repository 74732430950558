import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  FloralAccent,
  MDashes,
  SmallCapsTitle,
  Subline,
  UnderConstruction,
} from "../components";
import { Fancy, Nth } from "../Util";

function CalendarSquare({
  day,
  weekday,
  events,
}: {
  day: number;
  weekday: string;
  events: Array<React.ReactNode>;
}) {
  return (
    <div className="calendar-square">
      <span className="calendar-square-day">
        <span>{weekday}</span>
        <span>{day}th</span>
      </span>
      {events.map((event, i) => (
        <div key={i} className="calendar-square-event">
          {event}
        </div>
      ))}
    </div>
  );
}

function ScrollToLink({
  children,
  to,
}: {
  children: React.ReactNode;
  to: string;
}) {
  return (
    <a
      href=""
      onClick={(e) => {
        e.preventDefault();
        const el = document.getElementById(to.replace(/^#/, ""));
        el?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      {children}
    </a>
  );
}

export function Events() {
  return (
    <div className="section section-small">
      <MDashes>Events</MDashes>
      <p>There will be multiple events during the 3-day wedding.</p>
      <div className="events-calendar">
        <div className="calendar-square calendar-square-before" />
        <CalendarSquare
          day={26}
          weekday="Mon"
          events={[
            <ScrollToLink to="#monday">Arrive</ScrollToLink>,
            <ScrollToLink to="#mehendi">Mehendi</ScrollToLink>,
            <ScrollToLink to="#sangeet">Sangeet</ScrollToLink>,
          ]}
        />
        <CalendarSquare
          weekday="Tue"
          day={27}
          events={[
            <ScrollToLink to="#tuesday">Brunch & Vows</ScrollToLink>,
            <ScrollToLink to="#wedding">Wedding</ScrollToLink>,
            <ScrollToLink to="#afterparty">Afterparty</ScrollToLink>,
          ]}
        />
        <CalendarSquare
          weekday="Wed"
          day={28}
          events={[<ScrollToLink to="#wednesday">Goodbyes</ScrollToLink>]}
        />
        <div className="calendar-square calendar-square-after"></div>
      </div>
      <MDashes>Mumbai</MDashes>
      <SmallCapsTitle id="saturday">
        Saturday, <Nth>24</Nth> December
      </SmallCapsTitle>{" "}
      <Event
        name="Bombay Bash"
        id="party"
        time="Night"
        description={
          <>
            If you travel through Mumbai on your way to Kochi, you can meet us
            in Mumbai for a pre-wedding dinner!
            <Subline>Optional</Subline>
          </>
        }
      />
      <MDashes>Kochi</MDashes>
      <SmallCapsTitle id="monday">
        Monday, <Nth>26</Nth> December
      </SmallCapsTitle>{" "}
      <Event
        name="Mehendi & Makes"
        id="mehendi"
        time="Afternoon"
        description={
          <>
            <Quote who="Tradition">
              The <i>Mehendi</i> ceremony is a tradition that involves the the
              bride getting an elaborate henna tattoo design on her hands, arms
              and feet, which is meant to symbolize her love for the groom, and
              also serves as a beautifying ritual before the wedding. Sometimes,
              the groom also partakes in this ceremony, and traditionally the{" "}
              <i>Mehendi</i> was celebrated by the bride and groom seperately as
              a pre-wedding preparation, done amidst just close family.
            </Quote>
            <Quote who="Filisha">
              For our <i>Mehendi</i> we will invite our friends and families to
              join in the festivities without caring for the perfection expected
              of the elaborate bridal henna. Plenty of henna cones, a few henna
              artists, but mostly, your wishes and love finding their creative
              form and freedom on the willing hands of the bride and groom. We
              will also incorporate other crafts that speak to our
              interpretation of denoting love and pre-wedding preparations
              shared with the near and dear ones.
            </Quote>
            <Quote who="Evan">
              Making things is integral to how we bond as a family. We find the
              best moments in life happen when our hands are busy crafting,
              constructing, and creating. So we hope that the beginning of our
              wedding start with some creative making of things in mediums
              inspired by our cultures.
            </Quote>
            <Subline>
              <HashLink to="/travel#Clothing">
                Suggested Attire: Indian Artisan
              </HashLink>
            </Subline>
          </>
        }
      />
      <Event
        name="Sangh wali Sangeet "
        time="Evening"
        id="sangeet"
        description={
          <>
            <Quote who="Tradition">
              The word <i>Sangeet</i> translates to <i>music</i> in Hindi, and
              this event is a more modern custom added to Indian weddings. The
              night of the <i>Sangeet</i> event is celebrated as a musical setup
              by the two families, inspired heaviliy by Bollywood songs and
              dances. The cousins, uncles, aunts, and many extended relatives
              from both the bride and groom's side often meet for the first time
              to prepare for the <i>Sangeet</i> and put on an interprative
              musical about the bride and groom's love story.
            </Quote>
            <Quote who="Filisha">
              For our wedding we want to empahize the <i>Sangh</i>&mdash;our
              tribe&mdash;in the <i>Sangeet</i>. Marrying the Bollywood moves
              with the various other story telling genres of our tribe, we will
              blend it into a uniquely improvised night, full of music, dance,
              and improv.
            </Quote>
            <Quote who="Evan">
              Our <i>Sangeet</i> will have some creative reform for those who
              are left-footed in dance, but sure-footed in having fun. All you
              need is willing participation and stories galore.
            </Quote>
            <Subline>
              <HashLink to="/travel#Clothing">
                <i>Suggested Attire: Glam Night Formal</i>
              </HashLink>
            </Subline>
          </>
        }
      />
      <SmallCapsTitle id="tuesday">
        Tuesday, <Nth>27</Nth> December
      </SmallCapsTitle>{" "}
      <Event
        name="Brunch &amp; Vows"
        time="Morning"
        id="brunch"
        description={
          <>
            <Quote who="Tradition">
              Our wedding is the merging of two cultures, so not everything will
              be an old Indian tradition. We pick and choose the pieces that are
              meaningful to us and create our own culture along the way. <br />
              Evan's family is known to love a good <i>gelage</i>
              &mdash;German for <i>feast</i>&mdash;where we enjoy a huge spread
              of bread, cheese, cake, tea, and more! We also love the idea of
              writing our own vows and expressing them as part of our wedding.
              <br />
              This is also the time where we would like to exchange our vows and
              say our speeches. A time to express ourselves ahead of the wedding
              ceremony.
            </Quote>
            <Quote who="Filisha">
              Making this all about brunch but also those good heartfelt
              moments, is our most authentic rendition of the western wedding
              archetype.
            </Quote>
            <Quote who="Evan">
              Brunch is the best type of meal and no one can convince me
              otherwise.
            </Quote>
            <HashLink to="/travel#Clothing">
              <i>Suggested Attire: Resort Formal</i>
            </HashLink>
          </>
        }
      />
      <Event
        name="Wedding"
        time="Evening"
        id="wedding"
        description={
          <>
            <Quote who="Tradition">
              The wedding ceremony, where the bride and groom become officially
              married. In most Hindu/Jain weddings, a central fire is lit by the
              priest and the bride and groom recite vedic matras while taking
              circles around this fire (<i>pheras</i>), thereby getting "bonded
              in holy matrimony". There are a bunch of religious ceremonies
              before and after these <i>pheras</i>, which is a fun little rukus
              to witness. There is no tradition of having a bridal party, yet
              there is a lot of involvement of the family members and guests in
              the entire wedding. Various rituals are designated to be done by
              various family members, and friends and cousins often jostle in
              fun side-bar cultural games.
            </Quote>
            <Quote who="Filisha">
              Having seen Indian weddings galore, the ceremony holds far more
              cultural than religious significance to us. It is important to us
              that we apply these cultural practices with our personal nuances.
              Our wedding will have some fusion of traditional Indian with our
              very unique spin on it.
            </Quote>
            <Quote who="Evan">
              Indian wedding ceremonies are a wonderful thing to experience for
              those uninitiated. After the first Indian wedding I went to,
              Filisha couldn't believe how much fun I was having! What struck me
              was how many things would happen. In a typical western wedding,
              everyone focuses on the singular event of the bride and the groom
              standing by each other while the ceremony occurs. With Indian
              wedding ceremonies, there's so many small things happening! From
              ceremony, to cultural games, to food, and more. Everyone will have
              their own unique experience in traversing it all!
            </Quote>
            <HashLink to="/travel#Clothing">
              <i>Suggested Attire: Indian Traditional</i>
            </HashLink>
          </>
        }
      />
      <Event
        name="Un-Event"
        time="Night"
        id="afterparty"
        description={
          <>
            <Quote who="Filisha">
              The secret party. Not everything is in the books, some things just
              happen when you're in the know. And you know, when you're in the
              know.
            </Quote>

            <Quote who="Evan">
              This is one of those days when bed-time will be later than usual,
              but only for some of us.
            </Quote>
          </>
        }
      />
      <SmallCapsTitle id="wednesday">
        Wednesday, <Nth>28</Nth> December
      </SmallCapsTitle>{" "}
      <Event
        name="Goodbyes"
        time="Morning"
        id="goodbye"
        description={
          <>
            After all the events, we say goodbye! This is the day you will move
            on to the rest of your trip. Who knows what else awaits you in
            India!
          </>
        }
      />
      <div className=""></div>
      <div style={{ marginTop: "80px" }}>
        <h3>No gifts! </h3>
        You may notice we don't have a gift registry. That's intentional! <br />
        We won't accept any gifts. <br />
        Flights are expensive, your presence is truly enough. We mean it. <br />
        The only thing we would accept is something small and value-less you
        create yourself.
      </div>
    </div>
  );
}

function Event({
  name,
  id,
  time,
  description,
}: {
  name: React.ReactNode;
  id: string;
  time: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <div className="event" id={id}>
      <div className="left">
        <h2>{name}</h2>
        {/* <Fancy>{name as string}</Fancy> */}
        <div>
          <b>{time}</b>
        </div>
      </div>
      <div className="right">
        <div>{description}</div>
      </div>
    </div>
  );
}

function Quote({ who, children }: { who: string; children?: React.ReactNode }) {
  return (
    <div className="quote">
      <Subline>{who} says</Subline>
      <p>{children}</p>
    </div>
  );
}
