import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  FloralAccent,
  MDashes,
  SmallCapsTitle,
  UnderConstruction,
} from "../components";
import { Fancy, HeroImage } from "../Util";

type URLData = {
  n: string; // party names
  ps: Array<string>; // party members. Of length 1 for single person.
};

export const RSVPLocalStorageKey = "rsvp-info";
export const RSVPResultLocalStorageKey = "rsvp-result-info";

function parseRSVPData(data: string): URLData | null {
  try {
    const parsed = JSON.parse(data) as URLData;
    return parsed;
  } catch (err) {
    console.error(`could not parse ${data}`, err);
  }
  return null;
}

/**
 * 1. Users are sent a unique link containing a search param for=eyJuIjoiUnV0dSIsInAiOjF9 with some base64 encoded data
 * 2. That data is parsed to show the person's name for personalization
 * 3. We then store that data to local storage, so if you visit just /RSVP it can remember your name
 * 4. We remove the search param once stored so that the URL is the nice clean /RSVP immediately
 * 5. If you have not yet visited the unique link, the RSVP page will not be shown in the nav bar. Navigating directly shows a small error.
 *
 * This component manages the search params and local storage. The UI is rendered in the sub components.
 */
export function RSVP() {
  const [searchParams, setSearchParams] = useSearchParams();
  const details = searchParams.get("for");
  const searchParamData = b64_to_utf8(details ?? "");
  console.log("search params data: ", searchParamData);
  const localStorageData = localStorage.getItem(RSVPLocalStorageKey) as string;
  console.log("local storage data: ", localStorageData);

  const info =
    parseRSVPData(localStorageData) ?? parseRSVPData(searchParamData);

  React.useEffect(() => {
    if (searchParamData) {
      console.log(
        "Got new search param data, setting to local storage",
        searchParamData
      );
      localStorage.setItem(RSVPLocalStorageKey, searchParamData);
      const readItBack = localStorage.getItem(RSVPLocalStorageKey);
      if (readItBack != null) {
        // delete search param to clean up URL, now that we can load it from local storage
        setSearchParams({});
      } else {
        // if we failed to re-read the set storage state, it means local storage won't work. We need to keep the URL params.
      }
    }
  }, [searchParamData]);

  return (
    <div className="rsvp-outer">
      {info != null ? (
        <RSVPDetails partyName={info.n} members={info.ps} />
      ) : (
        <RSVPEmptyState />
      )}
    </div>
  );
}

function b64_to_utf8(b64: string) {
  return decodeURIComponent(escape(window.atob(b64)));
}

function FormQuestion({
  question,
  children,
  byline,
  required,
}: {
  question: React.ReactNode;
  children: React.ReactNode;
  byline?: React.ReactNode;
  required?: boolean;
}) {
  return (
    <div className="form-question">
      <div className="form-question-title">
        {required ? <span className="form-required-star">*</span> : null}
        {question}
      </div>
      <div className="form-byline">{byline}</div>
      <div className="button-row">{children}</div>
    </div>
  );
}

function RadioSet({
  options,
  name,
  required,
}: {
  options: Array<string>;
  name: string;
  required?: boolean;
}) {
  return (
    <div>
      {options.map((option, i) => {
        const id = `${name}-${option}`.replace(/ /g, "_");
        return (
          <div className="radio-option" key={i}>
            <input
              type="radio"
              id={id}
              name={name}
              value={option}
              required={required}
            />
            <label htmlFor={id}>{option}</label>
          </div>
        );
      })}
    </div>
  );
}
function ErrorMessage({ children }: { children: React.ReactNode }) {
  return <div className="error-message">{children}</div>;
}

type SubmissionState = null | "sending" | "confirmed" | "error";
function RSVPForm({
  partyName,
  members,
  children,
  setSubmissionState,
}: {
  partyName: string;
  members: Array<string>;
  children: React.ReactNode;
  setSubmissionState: (state: SubmissionState) => void;
}) {
  const ref = React.useRef<HTMLFormElement>(null);
  let navigate = useNavigate();

  return (
    <form
      ref={ref}
      data-netlify="true"
      name="rsvp"
      method="post"
      onSubmit={(e) => {
        e.preventDefault();
        if (ref.current == null) {
          return;
        }
        setSubmissionState("sending");
        const formData = new FormData(ref.current);
        console.log("raw form data: ", formData);
        const membersResponses = (
          [...formData.entries()] as Array<[string, string]>
        ).filter(([key]) => key.startsWith("willAttend"));
        console.log(membersResponses);
        const someMembersWillAttend = membersResponses.some(
          ([key, resp]) => resp.toLowerCase() === "yes"
        );
        const allMembersWillAttend = membersResponses.every(
          ([key, resp]) => resp.toLowerCase() === "yes"
        );
        // we assume members array is in the same order as form data's keys
        const membersAttending = members.filter(
          (name, i) => membersResponses[i][1].toLowerCase() === "yes"
        );
        console.log({
          someMembersWillAttend,
          allMembersWillAttend,
          membersAttending,
        });

        /* Fields being sent must match the ones in index.html so netlify will recognize them */
        const fields: { [key: string]: string } = {};
        fields["form-name"] = "rsvp";
        fields["party-name"] = partyName;
        fields["members-attending"] = membersAttending.join(", ");
        fields["some-members-will-attend"] = String(someMembersWillAttend);
        fields["all-members-will-attend"] = String(allMembersWillAttend);
        fields["num-attending"] = String(membersAttending.length);
        fields["num-invited"] = String(members.length);
        fields["willStayInMumbai"] = formData.get("willStayInMumbai") as string;
        fields["wantsHelpWithKochiFlights"] = formData.get(
          "wantsHelpWithKochiFlights"
        ) as string;
        fields["email"] = formData.get("email") as string;
        fields["dietary-restrictions"] = formData.get(
          "dietary-restrictions"
        ) as string;

        const body = new URLSearchParams(fields).toString();
        console.log("body", body);

        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body,
        })
          .then((response) => {
            if (!response.ok) {
              if (window.location.hostname === "evfiwedding.com") {
                throw new Error(response.statusText);
              } else {
                console.warn(
                  "ignoring error since we're not in production",
                  response.statusText
                );
              }
            }
            localStorage.setItem(
              RSVPResultLocalStorageKey,
              String(someMembersWillAttend)
            );
            setSubmissionState("confirmed");
            navigate(
              someMembersWillAttend ? "/RSVPThankYou" : "/RSVPThanksButNoThanks"
            );
          })
          .catch((err) => {
            console.error("error submitting form", err);
            setSubmissionState("error");
          });
      }}
    >
      {/* needed to tell netlify this is the same form that we put in the hidden HTML form */}
      <input type="hidden" name="form-name" value="rsvp" />
      {children}
    </form>
  );
}

/*************************************** Content below here ***************************************/

export function RSVPThankYou({ willAttend }: { willAttend?: "yes" | "no" }) {
  const localStorageWillAttend = localStorage.getItem(
    RSVPResultLocalStorageKey
  ) as "yes" | "no";
  console.log("local storage attendance data: ", localStorageWillAttend);
  const rsvpResponse = willAttend || localStorageWillAttend;
  const definitelyRSVPd =
    rsvpResponse != null && ["yes", "no"].includes(rsvpResponse.toLowerCase());
  const isOnSeparateThankYouPage = willAttend != null;
  if (!definitelyRSVPd) {
    return null;
  }
  return (
    <div className="rsvp-thank-you">
      <div className="got-rsvp-message">
        <div className="big-checkmark">&#10003;</div>
        We got your RSVP!
      </div>
      {rsvpResponse === "yes" ? (
        <div>We're so excited to see you in Kochi!</div>
      ) : rsvpResponse === "no" ? (
        <div>We're sad you won't be able to make it.</div>
      ) : null}
      <div>
        Let us know if anything changes about your <Link to="/rsvp">RSVP</Link>.
      </div>
      {rsvpResponse === "yes" ? (
        <>
          <div>You can start getting excited now.</div>
          <div>Be sure to read all about the wedding ahead of time.</div>
          <div className="list-of-links">
            <Link to="/events">Events &amp; Schedule</Link>
            <Link to="/travel">Travel</Link>
          </div>
        </>
      ) : null}
      <div>
        {isOnSeparateThankYouPage ? (
          <HeroImage src="couch_solid_bw.png" />
        ) : null}
      </div>
    </div>
  );
}

function RSVPEmptyState() {
  // TODO: more steps to fix this? let you RSVP anyway?
  return (
    <b>Please use the personal link sent to you to access the RSVP page.</b>
  );
}

function Spinner() {
  return <div className="spinner"></div>;
}

function RSVPDetails({
  partyName,
  members,
}: {
  partyName: string;
  members: Array<string>;
}) {
  const [submissionState, setSubmissionState] =
    React.useState<SubmissionState>(null);
  const partyHasPlusOnes = members.length > 1;
  return (
    <div className="rsvp-container">
      <RSVPThankYou />
      <div className="large-screen-only" style={{ position: "relative" }}>
        <FloralAccent
          src="/bw3.png"
          style={{ left: "-80px", top: "-155px" }}
          grow
          flip
          scale={0.8}
        />
        <FloralAccent
          src="/bw2.png"
          style={{ right: "-95px", top: "350px" }}
          flip
        />
        <FloralAccent
          src="/bw1.png"
          style={{ left: "-40px", top: "850px" }}
          grow
        />
      </div>
      <div className="small-screen-only">
        <FloralAccent
          inline
          src="/bw2.png"
          style={{
            transform: "translate(0px, 53px) scale(1.5)",
            transformOrigin: "left",
          }}
        />
      </div>
      <div className="rsvp">
        <MDashes>RSVP</MDashes>
        {/* <Fancy children={`RSVP`} /> */}
        {/* <div className="rsvp-name">Dearest {person},</div> */}
        <Fancy children={`Dearest ${partyName},`} />
        <div>
          We're very pleased to invite you to our wedding in India this
          December.
        </div>
        {partyHasPlusOnes ? null : (
          <div>Please note that we don't have capacity to offer +1s</div>
        )}
        <div>
          Be sure to read the <Link to="/events">events</Link> and{" "}
          <Link to="/travel">travel</Link> pages to learn all the details about
          the wedding.
        </div>
        <div>Please RSVP by June 4th.</div>
        <RSVPForm
          partyName={partyName}
          members={members}
          setSubmissionState={setSubmissionState}
        >
          {members.map((partyMember, i) => (
            <FormQuestion
              question={`Will ${
                members.length === 1 ? "you" : partyMember
              } be able to attend the wedding in Kochi?`}
              required
              key={i}
            >
              <RadioSet
                options={["yes", "no"]}
                name={`willAttend-${partyMember}`}
                required
              />
            </FormQuestion>
          ))}
          <FormQuestion
            question="Your Email"
            byline="To contact you with any important updates"
          >
            <input type="email" name="email" />
          </FormQuestion>

          <FormQuestion
            question="Will you stay with us in Mumbai at the Sea Princess hotel?"
            byline={
              <span>
                Recommended for all international travelers.
                <br />
                See{" "}
                <HashLink to="/travel#flying_into_mumbai">
                  travel page
                </HashLink>{" "}
                for more info
              </span>
            }
          >
            <RadioSet options={["yes", "no"]} name="willStayInMumbai" />
          </FormQuestion>
          <FormQuestion
            question="Should we connect you with our agent to book flights from Mumbai to Kochi?"
            byline={
              <span>
                Recommended.
                <br />
                See{" "}
                <HashLink to="/travel#domestic_flights">
                  flights page
                </HashLink>{" "}
                for more info
              </span>
            }
          >
            <RadioSet
              options={["yes", "no"]}
              name="wantsHelpWithKochiFlights"
            />
          </FormQuestion>
          <FormQuestion
            question="Any dietary restrictions?"
            byline="All food provided will be vegetarian"
          >
            <input
              placeholder="Vegan, Allergies..."
              name="dietary-restrictions"
            />
          </FormQuestion>
          {submissionState === "error" ? (
            <ErrorMessage>Failed to send RSVP. Try again?</ErrorMessage>
          ) : null}
          <button type="submit" style={{ position: "relative" }}>
            Submit
            {submissionState === "sending" ? <Spinner /> : null}
          </button>
        </RSVPForm>

        <div style={{ marginTop: "80px" }}>
          <h3>No gifts! </h3>
          You may notice we don't have a gift registry. That's intentional!{" "}
          <br />
          We won't accept any gifts. <br />
          Flights are expensive, your presence is truly enough. We mean it.{" "}
          <br />
          The only thing we would accept is something small and value-less you
          create yourself.
        </div>
      </div>
      <div className="small-screen-only">
        <FloralAccent
          inline
          src="/bw1.png"
          flip
          style={{
            float: "right",
            transform: "translate(0px, 0px) scale(1.5)",
            transformOrigin: "right",
          }}
        />
      </div>
    </div>
  );
}

/*
TODO: 

button or something if your name is wrong (in case we send wrong links or something)

explanation of +1s. Can't offer to everyone due to limited venue and we're paying for accomodation

No gifts! flights are expensive, your presence is truly enough. We mean it. 
There's no registry because we don't want gifts. No gifts! 
We won't accept them.
the only thing we would accept is something small and value-less you create yourself.

RSVP due date


we know flights are expensive, so we understand it can be hard to make the journey.
The wedding is directly after christmas, which not everyone may be comfortable with.
This is one of the best opportunities for people to take time off.
this is a cool excuse to travel somewhere you might not usually travel to (?)


"see travel page for more logistics"


*/
