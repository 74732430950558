import { Helmet } from "react-helmet";
import React from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { RSVPLocalStorageKey } from "./routes/RSVP";
// import { Fancy } from "./Util";

export function Header() {
  const [isSmall, setIsSmall] = React.useState(false);
  React.useEffect(() => {
    const scrollHandler = () => {
      if (
        document.body.scrollTop > 70 ||
        document.documentElement.scrollTop > 70
      ) {
        if (!isSmall) {
          setIsSmall(true);
        }
      } else {
        if (isSmall) {
          setIsSmall(false);
        }
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [isSmall]);

  useSearchParams(); // re-render header when search params change, to detect if we've set the RSVP user name

  const [isHamburgerExpanded, setHamburgerExpanded] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setHamburgerExpanded(false);
  }, [location, setHamburgerExpanded]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <script
          src="https://kit.fontawesome.com/5000bf2773.js"
          crossOrigin="anonymous"
        ></script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@300;400&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Qwitcher+Grypen&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <header id="header" className={isSmall ? "header-small" : ""}>
        <i className="title-sub">&mdash; The wedding of &mdash;</i>
        {/* <h1 class="title">
          <Fancy>Evan &amp; Filisha</Fancy>
        </h1> */}
        <div
          className="hamburger"
          onClick={() => setHamburgerExpanded((value) => !value)}
        >
          <HamburgerIcon />
        </div>
        <h1 className="title">Evan &amp; Filisha</h1>

        <nav className={isHamburgerExpanded ? "expanded" : "collapsed"}>
          <Linky to="/">Home</Linky>
          <Linky to="/events">Events &amp; Schedule</Linky>
          <Linky to="/travel">Travel</Linky>
          <Linky to="/clothes">Clothes</Linky>
          {/* TODO: Shuttle pickup no longer in header, but route still accessible */}
          {/* <Linky to="/pickup">Pickup</Linky> */}
          {/* TODO: RSVP no longer in header, but route still accessible */}
          {/* {localStorage.getItem(RSVPLocalStorageKey) ? (
            <Linky to="/RSVP">RSVP</Linky>
          ) : null} */}
        </nav>
        <div
          className={`behind-nav-tint ${
            isHamburgerExpanded ? "expanded" : "collapsed"
          }`}
          onClick={() => setHamburgerExpanded(false)}
        />
      </header>
    </>
  );
}

function Linky({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? "active" : undefined)}
      to={to}
    >
      {children}
    </NavLink>
  );
}

function HamburgerIcon() {
  return (
    <svg viewBox="0 0 100 80" width="40" height="40" className="hamburger-icon">
      <rect width="100" height="15"></rect>
      <rect y="30" width="100" height="15"></rect>
      <rect y="60" width="100" height="15"></rect>
    </svg>
  );
}
