import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  FloralAccent,
  MDashes,
  SmallCapsTitle,
  Subline,
  UnderConstruction,
} from "../components";
import { Fancy, HeroImage } from "../Util";

export function Pickup() {
  return (
    <div className="pickup-outer">
      <PickupDetails />
    </div>
  );
}

function FormQuestion({
  question,
  children,
  byline,
  required,
}: {
  question: React.ReactNode;
  children: React.ReactNode;
  byline?: React.ReactNode;
  required?: boolean;
}) {
  return (
    <div className="form-question">
      <div className="form-question-title">
        {required ? <span className="form-required-star">*</span> : null}
        {question}
      </div>
      <div className="form-byline">{byline}</div>
      <div className="button-row">{children}</div>
    </div>
  );
}

function RadioSet({
  options,
  name,
  required,
  onChange,
}: {
  options: Array<string>;
  name: string;
  required?: boolean;
  onChange?: (choice: string) => unknown;
}) {
  return (
    <div>
      {options.map((option, i) => {
        const id = `${name}-${option}`.replace(/ /g, "_");
        return (
          <div className="radio-option" key={i}>
            <input
              type="radio"
              id={id}
              name={name}
              value={option}
              required={required}
              onChange={(e) => onChange?.(e.target.value)}
            />
            <label htmlFor={id}>{option}</label>
          </div>
        );
      })}
    </div>
  );
}

function Select({
  options,
  name,
  required,
  defaultIndex,
  onChange,
}: {
  options: Array<string>;
  name: string;
  required?: boolean;
  defaultIndex?: number;
  onChange?: (choice: string) => unknown;
}) {
  return (
    <div>
      <select
        name={name}
        required={required}
        onChange={(e) => onChange?.(e.target.value)}
      >
        {options.map((option, i) => {
          return (
            <option
              className="select-option"
              key={i}
              value={option}
              selected={defaultIndex === i}
            >
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
}

function ErrorMessage({ children }: { children: React.ReactNode }) {
  return <div className="error-message">{children}</div>;
}

type SubmissionState = null | "sending" | "confirmed" | "error";
function PickupForm({
  children,
  setSubmissionState,
}: {
  children: React.ReactNode;
  setSubmissionState: (state: SubmissionState) => void;
}) {
  const ref = React.useRef<HTMLFormElement>(null);
  let navigate = useNavigate();

  return (
    <form
      ref={ref}
      data-netlify="true"
      name="pickup"
      method="post"
      onSubmit={(e) => {
        e.preventDefault();
        if (ref.current == null) {
          return;
        }
        setSubmissionState("sending");
        const formData = new FormData(ref.current);
        console.log("raw form data: ", formData);
        console.log("form entries: ", [...formData.entries()].join("\n"));

        /* Fields being sent must match the ones in index.html so netlify will recognize them */
        const fields: { [key: string]: string } = {};
        fields["form-name"] = "pickup";
        for (const [key, value] of formData.entries()) {
          fields[key] = value.toString();
        }

        const body = new URLSearchParams(fields).toString();
        console.log("body", body);

        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body,
        })
          .then((response) => {
            if (!response.ok) {
              if (window.location.hostname === "evfiwedding.com") {
                throw new Error(response.statusText);
              } else {
                console.warn(
                  "ignoring error since we're not in production",
                  response.statusText
                );
              }
            }
            setSubmissionState("confirmed");
          })
          .catch((err) => {
            console.error("error submitting form", err);
            setSubmissionState("error");
          });
      }}
    >
      {/* needed to tell netlify this is the same form that we put in the hidden HTML form */}
      <input type="hidden" name="form-name" value="pickup" />
      {children}
    </form>
  );
}

/*************************************** Content below here ***************************************/

function Spinner() {
  return <div className="spinner"></div>;
}

function PickupDetails() {
  const [submissionState, setSubmissionState] =
    React.useState<SubmissionState>(null);

  const [
    saidYesToOutOfSeaPrincessShuttle,
    setSaidYesToOutOfSeaPrincessShuttle,
  ] = React.useState(false);
  const [saidYesToIntoRamadaShuttle, setSaidYesToIntoRamadaShuttle] =
    React.useState(false);
  const [saidYesToOutOfRamadaShuttle, setSaidYesToOutOfRamadaShuttle] =
    React.useState(false);
  const [intoKochiFlightOnProperDay, setIntoKochiFlightOnProperDay] =
    React.useState(true);
  const [outOfKochiFlightOnProperDay, setOutOfKochiFlightOnProperDay] =
    React.useState(true);

  return (
    <div className="pickup-container">
      <div className="large-screen-only" style={{ position: "relative" }}>
        <FloralAccent
          src="/bw3.png"
          style={{ left: "-80px", top: "75px" }}
          grow
          flip
          scale={0.8}
        />
        <FloralAccent
          src="/bw2.png"
          style={{ right: "-175px", top: "140px" }}
          flip
        />
        <FloralAccent
          src="/bw1.png"
          style={{ left: "-40px", top: "850px" }}
          grow
        />
        <FloralAccent
          src="/bw3.png"
          style={{ right: "-80px", top: "1550px" }}
          scale={0.8}
          grow
        />
      </div>
      <div className="small-screen-only">
        <FloralAccent
          inline
          src="/bw2.png"
          style={{
            transform: "translate(0px, 23px) scale(1.3)",
            transformOrigin: "left",
          }}
        />
      </div>
      <div className="pickup">
        <MDashes>Hotel Pickup and Dropoff</MDashes>
        <p>
          We will provide shuttles to make trips to/from the airport easier.
          <br />
          Sign up using this form so we can arrange for your transport.
        </p>

        <ErrorMessage>Please fill out by October 1st.</ErrorMessage>

        <p>
          After completing this form, we will email you with your shuttle
          details in October.
        </p>
        <p>
          Haven't booked Kochi flights yet? We recommend booking as soon as
          possible—some flights are already completely booked!
        </p>

        <SmallCapsTitle>What shuttles are provided?</SmallCapsTitle>
        <div className="shuttles-grid" style={{ display: "grid" }}>
          {/***********************/}
          <span>❌</span>
          <span>Mumbai Airport</span>
          <span>&rarr;</span>
          <span>Sea Princess</span>
          <span>Not provided*</span>
          {/***********************/}
          <span>✅</span>
          <span>Sea Princess</span>
          <span>&rarr;</span>
          <span>Mumbai Airport</span>
          <span>Monday, December 26th</span>
          {/***********************/}
          <span>✅</span>
          <span>Kochi Airport</span>
          <span>&rarr;</span>
          <span>Ramada Kochi</span>
          <span>Monday, December 26th</span>
          {/***********************/}
          <span>✅</span>
          <span>Ramada Kochi</span>
          <span>&rarr;</span>
          <span>Kochi Airport</span>
          <span>Wednesday, December 28th</span>
        </div>
        <div>
          <Subline>
            *Guests will be flying into Mumbai at many different times, so we
            can't provide a shuttle from the Mumbai International Airport to the
            Sea Princess hotel.
            <br /> Note: It is only a 10-15 minute drive from the airport to the
            Sea&nbsp;Princess; we recommend taking an Uber or taxi.
          </Subline>
        </div>

        <br />
        <br />
        <PickupForm setSubmissionState={setSubmissionState}>
          {/* <SmallCapsTitle>Shuttle Form</SmallCapsTitle> */}
          <MDashes>Shuttle Form</MDashes>
          <FormQuestion
            required={true}
            question="Your party members"
            byline="Including all members taking the shuttle"
          >
            <input
              placeholder="e.g. Evan, Filisha"
              name="party-members"
              required={true}
            />
          </FormQuestion>

          {/*****************************************************************/}

          <SmallCapsTitle>Into Ramada Kochi Resort</SmallCapsTitle>

          <FormQuestion
            question="Will you be taking the shuttle from the Sea Princess hotel to the Mumbai International Airport?"
            required
          >
            <RadioSet
              required={true}
              options={["yes", "no"]}
              name="from-sea-princess-will-take-shuttle"
              onChange={(choice) =>
                setSaidYesToOutOfSeaPrincessShuttle(choice === "yes")
              }
            />
          </FormQuestion>

          <FormQuestion
            question="Will you be taking the shuttle from Cochin International Airport to Ramada Kochi Resort?"
            required={true}
          >
            <RadioSet
              required={true}
              options={["yes", "no"]}
              name="flight-into-kochi-will-take-shuttle"
              onChange={(choice) =>
                setSaidYesToIntoRamadaShuttle(choice === "yes")
              }
            />
          </FormQuestion>

          {saidYesToIntoRamadaShuttle || saidYesToOutOfSeaPrincessShuttle ? (
            <>
              <FormQuestion
                question="What day is your flight into Kochi?"
                required
              >
                <Select
                  required={true}
                  defaultIndex={1}
                  options={[
                    "Sunday, December 25th",
                    "Monday, December 26th",
                    "Tuesday, December 27th",
                    "other",
                  ]}
                  name="flight-into-kochi-day"
                  onChange={(choice) => {
                    setIntoKochiFlightOnProperDay(
                      choice === "Monday, December 26th"
                    );
                  }}
                />
              </FormQuestion>
              {intoKochiFlightOnProperDay ? null : (
                <ErrorMessage>
                  Note: We can only guarantee accommodation for Monday flights.
                  Reach out to us for details.
                </ErrorMessage>
              )}

              <FormQuestion
                question="What time is your flight into Kochi departing?"
                required
              >
                <input type="time" name="flight-into-kochi-time" required />
              </FormQuestion>

              <FormQuestion
                question="What is your flight number into Kochi?"
                byline="This will help us verify which flights have which people"
                required
              >
                <input
                  placeholder="e.g. AI 691, 6E 5343"
                  name="flight-into-kochi-number"
                  required
                />
              </FormQuestion>
            </>
          ) : null}

          {/*****************************************************************/}

          <SmallCapsTitle>Out of Ramada Kochi Resort</SmallCapsTitle>
          <FormQuestion
            question="Will you be taking the shuttle from Ramada Kochi Resort to Cochin International Airport?"
            required={true}
          >
            <RadioSet
              required={true}
              options={["yes", "no"]}
              name="flight-from-kochi-will-take-shuttle"
              onChange={(choice) =>
                setSaidYesToOutOfRamadaShuttle(choice === "yes")
              }
            />
          </FormQuestion>

          {!saidYesToOutOfRamadaShuttle ? null : (
            <>
              <FormQuestion
                question="What day is your flight out of Kochi?"
                required
              >
                <Select
                  defaultIndex={1}
                  options={[
                    "Tuesday, December 27th",
                    "Wednesday, December 28th",
                    "Thursday, December 29th",
                    "other",
                  ]}
                  name="flight-from-kochi-day"
                  onChange={(choice) => {
                    setOutOfKochiFlightOnProperDay(
                      choice === "Wednesday, December 28th"
                    );
                  }}
                />
              </FormQuestion>
              {outOfKochiFlightOnProperDay ? null : (
                <ErrorMessage>
                  Note: We can only guarantee accommodation for Wednesday
                  flights. Reach out to us for details.
                </ErrorMessage>
              )}

              <FormQuestion
                question="What time is your flight out of Kochi departing?"
                required
              >
                <input type="time" name="flight-from-kochi-time" required />
              </FormQuestion>

              <FormQuestion
                question="What is your flight number out of Kochi?"
                byline="This will help us verify which flights have which people"
                required
              >
                <input
                  placeholder="e.g. AI 0055, 6E 5308"
                  name="flight-from-kochi-number"
                  required
                />
              </FormQuestion>
            </>
          )}

          {/*****************************************************************/}

          <br />
          <br />

          {submissionState === "error" ? (
            <ErrorMessage>Failed to send form. Try again?</ErrorMessage>
          ) : null}
          {submissionState !== "confirmed" ? (
            <button type="submit" style={{ position: "relative" }}>
              Submit
              {submissionState === "sending" ? <Spinner /> : null}
            </button>
          ) : (
            <div>✅ Your response has been recorded!</div>
          )}
        </PickupForm>
      </div>

      <div className="large-screen-only" style={{ position: "relative" }}>
        <FloralAccent
          inline
          src="/bw1.png"
          style={{
            position: "absolute",
            top: "-250px",
            left: 0,
          }}
        />
      </div>

      <div className="small-screen-only">
        <FloralAccent
          inline
          src="/bw1.png"
          flip
          style={{
            float: "right",
            transform: "translate(0px, 0px) scale(1.5)",
            transformOrigin: "right",
          }}
        />
      </div>
    </div>
  );
}
