import React from "react";

export function FloralAccent({
  src,
  style,
  width,
  grow,
  flip,
  scale,
  inline,
  imgStyle,
}: {
  src: string;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  width?: string;
  grow?: boolean;
  flip?: boolean;
  scale?: number;
  inline?: boolean;
}) {
  return (
    <div
      className={`floral-accent ${grow ? "grow" : ""} ${
        inline ? "floral-inline" : ""
      }`}
      style={style}
    >
      <img
        src={src}
        style={{
          width,
          transform:
            (flip ? "scaleX(-1)" : "") +
            (scale != null ? `scale(${scale})` : ""),
          ...imgStyle,
        }}
        alt=""
        role="presentation"
      />
    </div>
  );
}

export function MDashes({ children }: { children: React.ReactNode }) {
  return (
    <div className="mdashes-title">
      <span>&mdash;&nbsp;</span>
      <span>{children}</span>
      <span>&nbsp;&mdash;</span>
    </div>
  );
}

export function UnderConstruction() {
  return (
    <div className="under-construction">
      <span>🚧</span> This page is still under construction 🚧
    </div>
  );
}

export function SmallCapsTitle({
  children,
  id,
  onClick,
}: {
  children?: React.ReactNode;
  id?: string;
  onClick?: () => void;
}) {
  return (
    <div id={id} className="small-caps-title" onClick={onClick}>
      {children}
    </div>
  );
}

export function Subline({ children }: { children?: React.ReactNode }) {
  return <div className="subline">{children}</div>;
}
