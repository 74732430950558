import React from "react";
import { Link } from "react-router-dom";
import { FloralAccent, MDashes } from "../components";
import { HeroImage, Nth } from "../Util";
// import { Fancy } from "../Util";

export function SaveTheDate() {
  return (
    <>
      {/* <HeroImage src="/us/IMG_4073.jpeg" />
              <HeroImage src="/newbg.png" />
              <HeroImage src="/newbg2.png" />
              <HeroImage src="/newbg3.png" />
              <HeroImage src="/newbg5.png" />
              <HeroImage src="/couch_alpha_bw.png" />
              <HeroImage src="/couch_solid_bw.png" />
              <HeroImage src="/hawaii_bw.png" /> */}
      {/* <HeroImage src="/couch_solid_bw.png" /> */}
      <HeroImage src="/bridge_cc.png" />
      {/* <div className="heroImageResponsive"></div> */}

      <div className="area">
        <FloralAccent
          src="/bw2.png"
          style={{ left: "-40px", top: "-15%" }}
          grow
        />
        <FloralAccent src="/bw3.png" style={{ right: "-30px", top: "120px" }} />
        <FloralAccent
          src="/bw1.png"
          style={{ left: "-40px", top: "520px" }}
          grow
        />

        <div className="section">
          <div className="boxxy">
            <div style={{ marginTop: "30px" }}>
              <MDashes>Save The Date</MDashes>
            </div>

            {/* <div className="section">
          <Fancy>Evan Krause</Fancy>
          <Fancy>and</Fancy>
          <Fancy>Filisha Shah</Fancy>
         */}
            <div className="date">
              <div style={{ fontSize: "130%", marginBottom: "-30px" }}>
                December
              </div>
              <div className="larger-date">
                <Nth>26</Nth>
                &nbsp; | &nbsp;
                <Nth>27</Nth>
                &nbsp; | &nbsp;
                <Nth>28</Nth>
              </div>
              <div style={{ fontSize: "150%", marginTop: "-35px" }}>2022</div>
              <div style={{ fontSize: "120%", margin: 0, marginTop: "70px" }}>
                Mumbai | Kochi
              </div>
              <div style={{ fontSize: "100%", margin: 0 }}>India</div>
            </div>
          </div>
        </div>
      </div>

      <HeroImage src="/hawaii_new.png" />
      <div className="accents accents-responsive">
        <FloralAccent
          src="/bw2.png"
          style={{ left: "-40px", top: "-210px" }}
          width="30vw"
        />
        <FloralAccent
          src="/bw1.png"
          style={{ left: "-40px", top: "-140px", transform: "scaleX(-1)" }}
          width="30vw"
        />
      </div>
      <div className="footer">
        <MDashes>What to expect</MDashes>

        <div>
          <p>
            Most Indian weddings are a sum-total of three to four events,
            leading up to the main ceremony. Our wedding will be much the
            same&mdash;a 3 days, 2 nights affair at a resort in the southern
            coastal town of Kochi.
          </p>
          <p>
            The resort will be booked out for our wedding, with the cost of
            accomodation and food covered by us. Before heading to Kochi, we
            will receive all our guests in Mumbai, and start our celebrations
            there. Our invitation extends to all the events that comprise our
            wedding.
          </p>
          <p>
            Use this website to learn more about the events and how to plan your
            travel. <br />
            We hope to share these days with you.
          </p>
          <div className="list-of-links">
            <Link to="/events">Events &amp; Schedule</Link>
            <Link to="/travel">Travel</Link>
          </div>
        </div>

        <div className="accents">
          <FloralAccent
            src="/bw2.png"
            style={{ right: "-50px", top: "-25px", transform: "scaleX(-1)" }}
          />
        </div>
      </div>
    </>
  );
}
