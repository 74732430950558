import React from "react";
import { FloralAccent, SmallCapsTitle, UnderConstruction } from "../components";
import { HashLink } from "react-router-hash-link";

function mungeId(name: string): string {
  return name.replace(/ /g, "_");
}

function Sections({
  children,
}: {
  children: Array<React.ReactElement<SectionProps>>;
}) {
  const names = children.map((child, i) => (
    <a key={i} href={`#${mungeId(child.props.title)}`}>
      {child.props.title}
    </a>
  ));
  return (
    <div style={{ position: "relative" }}>
      <div className="section-links">
        <SmallCapsTitle>Quick Links</SmallCapsTitle>
        <br />
        {names}
      </div>
      <div style={{ width: "100vw", position: "relative" }}>
        <FloralAccent
          src="/bw1.png"
          style={{ left: "-80px", top: "-340px", right: "unset" }}
          imgStyle={{ maxWidth: "380px" }}
          grow
          scale={0.8}
        />
        <FloralAccent
          src="/bw3.png"
          style={{ top: "-240px", right: "-70px" }}
          imgStyle={{ maxWidth: "280px" }}
          grow
          scale={0.8}
        />
      </div>
      <hr />
      <div className="travel-main-content">{children}</div>
    </div>
  );
}

type SectionProps = {
  title: string;
  children: React.ReactNode;
};
function Section({ title, children }: SectionProps) {
  return (
    <>
      <SmallCapsTitle id={mungeId(title)}>{title}</SmallCapsTitle>
      {children}
    </>
  );
}

export function Travel() {
  return (
    <div className="section">
      <div className="travel">
        <p>
          All your questions on logistics, travelling, attire and India will be
          answered in this section. If you are a local traveller or are well
          versed in Indian culture and wedding etiquette you may want to only
          consider the domestic travel part of the page.
        </p>

        <hr />
        <Sections>
          <Section title="How long should I visit?">
            <p>
              Travelling to India from the US is a long journey! Expect flights
              to take a full 24&nbsp;hours from the US west coast, with a nearly
              12&nbsp;hour time difference. This means it's not practical to
              come for JUST the 3-day wedding. We would generally recommend most
              people try to stay for 7-10 days, with 5 days being an absolute
              minimum. The longer you stay, the more you'll get out of the
              experience, since you'll be less stressed about the overhead of
              dealing with travel. This is why we planned the trip during US
              holidays where people could actually take the time to travel
              abroad.
            </p>
            <p>
              If travelling from the US, please consider that if you want to
              arrive on a given day, you'll need to leave a day and half
              beforehand.
            </p>
            <div id="flying_into_mumbai">
              <b>Flying into and staying in Mumbai</b>
            </div>
            <p>
              Although the main wedding venue will be in Kochi, Kerela, we
              recommend flying into Mumbai if coming from the US. International
              flights to Kochi are much more expensive compared to Mumbai.
            </p>
            <p>
              We expect many guests coming from the US will fly into Mumbai a
              few days before going to Kochi on the 26th. We have reserved a
              block-rate at the{" "}
              <a href="http://www.seaprincess.com/">Sea Princess Hotel</a>, so
              all our guests coming through Mumbai can stay together here. If
              you are flying into Mumbai, we strongly recommend you stay at this
              hotel. <br />
              We will also help arrange for travel from the Mumbai Airport to
              the hotel. Unfortunately we cannot cover the cost of your stay at
              the hotel in Mumbai like we will in Kochi. However, we have a good
              deal on the rate at around $50-100 USD per night for a double twin
              room. Please let us know in your RSVP if you will be joining us in
              Mumbai so we can help arrange for your stay.
            </p>
            <br />
            <div>
              <b>How long to stay after the wedding</b>
            </div>
            <p>
              We have 3 recommended archetypes of how you can plan your trip:
              <ol>
                <li>
                  <b>Condensed trip: 7 days.</b> Fly into Mumbai on the 25th
                  (leave on 23rd). Fly to Kochi on the 26th. Fly out of Kochi on
                  the 28th. Fly out of Mumbai on the 30th.
                </li>
                <li>
                  <b>Recommended: 10 days.</b> Fly into Mumbai on the 23rd
                  (leave on 21st). Fly to Kochi on the 26th. Fly out of Kochi on
                  the 28th. Maybe travel to another location like Goa. Fly out
                  of Mumbai on the 2nd, or fly out of another city like New
                  Delhi.
                </li>
                <li>
                  <b>Explorer: 14 days.</b> Fly into Mumbai on the 20th (leave
                  on 18th). Explore the city. Fly to Kochi on the 26th. Fly out
                  of Kochi on the 28th. Travel to different cities like Goa, New
                  Delhi, Jaipur. Make your way back to Mumbai to the 2nd, or fly
                  out of another city like New Delhi.
                </li>
              </ol>
              It's also possible to fly in and out of Kochi international
              airport directly, to reduce the amount of flying and skip Mumbai.
              It will likely be more expensive to buy such flights or require
              more connections.
            </p>
          </Section>
          <Section title="Flights">
            <p>
              <p>
                <b>International Flights: </b>
                The flights from the west coast of the US to Mumbai will always
                have at least one stop-over. If you're flying from NYC, there is
                a direct flight to Mumbai. We recommend flying over Europe or
                Central Asia as the fastest and most cost-effective airlines.
                Due to COVID and the current political climate in Europe, the
                flight prices are fluctuating a lot, and we've been monitoring
                them for the last couple of months. We recommend doing the same
                once you decide on your duration of the trip. As a general rule
                of thumb, if you get a flight under $1200 you should book
                it&mdash;that's a good deal. If the price is between
                $1500&ndash;$1700, continue to track the route for a price drop
                or use different dates. Recommended Airlines: Emirates, Qatar,
                Lufthansa, United, Air India, British, KLM in that order. If you
                have questions about a route or airline you're choosing feel
                free to message us for advice.
              </p>
              <p id="domestic_flights">
                <b>Domestic Flights: </b>
                Since we're recommending our guests to start their trip in
                Mumbai, it will require flying from Mumbai to Kochi for the
                wedding. This flying time from Mumbai to Kochi is less than 2
                hours.
                <br />
                We have a travel agent we are working with to help book these
                flights. Let us know if your RSVP if you would like help with
                this. You can also reach out directly to&nbsp;
                <a href="mailto:id-namita@siddhivinayaktravels.com">
                  id-namita@siddhivinayaktravels.com
                </a>
                . You'll need to include your name exactly as it appears on your
                passport.
                <br />
              </p>
            </p>
          </Section>
          <Section title="The Venue">
            <p>
              The 3-day wedding will be held at the{" "}
              <a href="https://www.ramadakochi.com/">Ramada Kochi</a> resort in
              Kochi, Kerela. The resort boasts the longest pool in Asia,
              Ayurvedic Massages, and a scenic waterfront view.
            </p>

            <p>
              We'll have the entire resort to ourselves! Your 3-day, 2-night
              stay at the Ramada Kochi is covered by us. This includes all meals
              for all the different events.
            </p>
            <div className="image-mosaic">
              <div>
                <img src="/ramada1.jpg" alt="remada kochi" />
              </div>
              <div>
                <img src="/ramada2.jpg" alt="remada kochi" />
              </div>
            </div>
          </Section>
          <Section title="Clothing">
            <a href="/clothes">
              See our clothing page for specific suggestions.
            </a>
            <p>
              You may need to atune yourself to the common Indian traditional
              attire.
              <ul>
                <li>
                  <b>Sari</b> which is an elaborately tied drape of fabric that
                  is worn from the shoulders to the ankles, covering one or both
                  of your arms.
                </li>
                <li>
                  <b>Lehenga</b> is another popular option, but unlike a saree,
                  this is typically a two-piece outfit consisting of a crop top
                  and full skirt, with a long scarf like fabric called a
                  dupatta.
                </li>
                <li>
                  <b>Sharara and Kurta</b> which is a type of suit featuring
                  wide-leg, palazzo-style pants and a matching tunic top
                  (sometimes called a kurti or kurta). The set is typically
                  decorated with sparkly beading, brocade patterns, and
                  embroidery.
                  <li>
                    Men can wear either a long-sleeved tunic (kurti) or a
                    knee-length jacket (called a sherwani) with matching pants.
                  </li>
                </li>
              </ul>
              Both men and women’s clothes come in dazzling, bright colors with
              lots of patterns, embellishments, and prints. If you live in an
              area with a large Indian-American population, try a local Indian,
              Pakistani, or Nepalese market, which will likely sell clothing.
              Stay tuned for more links and resources in a few months to help
              you source these outfits for the wedding.
            </p>
          </Section>
          <Section title="Wedding Etiquette">
            <p>
              Indian weddings vary a lot according the regions of the country.
              Filisha is a <i>Gujarati</i> (which is an Indian state on the west
              of the country boarding Pakistan, north of Mumbai and Kochi){" "}
              <i>Jain</i> and these weddings do not contain alcohol and only
              serve vegetarian and vegan food as part of their religious and
              cultural value system. Our wedding will therefore not be serving
              any alcohol. Breakfast, Lunch, Tea-time, and Dinner will be served
              throughout the wedding as part of the events, and will all be
              vegetarian. If you wish to have alcoholic beverages, we ask that
              you enjoy them in your rooms, and avoid displaying the alcohol you
              carry to the hotel staff. Please note alcohol is not included in
              our package with the resort and won't be automatiaclly paid for.
              We do however hope that you will enjoy the wedding and our events
              mostly in the spirit of how we have planned for them to
              be&mdash;clear-eyed, present, and full of joyous love.
            </p>
          </Section>
          <Section title="Things to Know">
            <p>
              <ul>
                <li>
                  US citizens need a visa to visit India. You can get an{" "}
                  <a href="https://indianvisaonline.gov.in/evisa/tvoa.html">
                    e-visa pretty easily online.
                  </a>
                  . While it generally only takes &lt;72&nbsp;hours to get an
                  e-tourist visa, we recommend applying early once you know your
                  travel dates.
                </li>
                <li>
                  Check when your passport expires.{" "}
                  <b>Passport must be valid for 6 months AFTER TRAVEL DATE</b>{" "}
                  to get a visa! Renew your passport early!
                </li>
                <li>
                  See{" "}
                  <a href="https://wwwnc.cdc.gov/travel/destinations/traveler/none/india">
                    CDC's travel health recommendations
                  </a>{" "}
                  for information about diseases and vaccinations. Consider
                  visiting your doctor for a prescription for malaria medicine
                  (optional) and to check up on your vaccinations. Mosquitos are
                  common, especially in damp coastal areas like Kochi and
                  Mumbai, so insect repellent is useful. We also recommend
                  bringing medicine for upset stomach.
                </li>
                <li>
                  The hotel has wifi, but you may want to enable an
                  international data plan on your phone. Most big phone networks
                  have relatively simple opt-in pay-per-day plans for this.
                </li>
              </ul>
            </p>
          </Section>
          <Section title="COVID-19">
            <p>
              COVID travel restrictions in India were just recently reduced.{" "}
              <b>You no longer need to show a negative PCR test</b> or upload
              anything to the AirSuvidha portal.
            </p>
            <p>
              <a href="https://in.usembassy.gov/covid-19-information/">
                See official COVID travel page for more information.
              </a>
            </p>
            <p>
              We expect all guests to be vaccinated against COVID-19. We won't
              have a mask requirement, though you can of course wear masks if
              it's more comfortable. We expect to have around 130 guests who
              you'll be interacting with in mostly outdoor or semi-outdoor
              environments. If you have any symptoms or test positive near the
              dates of the wedding we will respectfully ask you to not attend
              the functions.
            </p>
          </Section>
          <Section title="What to expect in India">
            <p>
              <div>
                <b>Weather</b>
              </div>
              December is an excellent month for weather in this part of India.
              It is typically slightly less hot, at around
              23&deg;C&ndash;29&deg;C (74&deg;F&ndash;84&deg;F), with much fewer
              rainy days (~20% chance).
            </p>
            <p>
              <div>
                <b>Food</b>
              </div>
              Street food is delicious and usually very safe to eat, but we
              recommend only eating piping hot street food. Food in restaurants
              is generally quite safe.
              <br />
              <b>Don't drink tap water</b>. Always drink bottled water. Don't
              ask for ice in your drink, it may be frozen tap water. Usually
              restaurants offer chilled bottled water.
            </p>
            <p>
              <div>
                <b>What to Pack</b>
              </div>
              <SmallUl>
                <li>Swimming Suit</li>
                <li>
                  Clothing for events, see{" "}
                  <HashLink to="#Clothing">recommendations</HashLink>
                </li>
                <li>Sunblock</li>
                <li>Clothes for warm weather (Shorts, sandals)</li>
                <li>Insect Repellant</li>
                <li>Malaria Medicine</li>
                <li>Tums or other medicine</li>
                <li>E-visa</li>
                <li>Up-to-date passport</li>
                <li>Vaccine Card</li>
                <li>No gifts (seriously)</li>
              </SmallUl>
            </p>
          </Section>
          <Section title="Tips">
            <p>
              <ul>
                <li>
                  <b>To minimize jetlag</b>: Be on India time as soon as your
                  flight takes off. Sleep on the plane when it's night time in
                  India. That may or may not be when the plane dims the lights.
                  When you arrive in India, avoid napping. Sleep right away if
                  you land at night, wake up at a normal time, then stay awake
                  all day without napping.
                </li>
                <li>
                  <b>Buy stuff!</b> Many things are cheap in India, especially
                  services and hand-crafted things. Getting a fancy haircut can
                  be as little as 1/5 the cost as the US. Fancy clothes are also
                  very reasonably priced. Things not to buy in India includes
                  imported stuff like electronics.
                </li>
                <li>
                  <b>Leave room in your stomach</b>. It's common for Indian
                  people offer you food, even while you're eating. If you leave
                  some buffer room you won't get over-full and can try more
                  things in small quantities.
                </li>
                <li>
                  <b>Leave some room in your suitcase to bring stuff home!</b>{" "}
                  You won't leave empty-handed.
                </li>
                <li>
                  <b>Consider brining a transformer</b>. Indian electricity runs
                  at 220V unlike the US. Some sensitive electronics require
                  step-down transformers. Some electronics natively support both
                  110 and 220, like most phones and some laptops. Electric
                  razers often require a transformer.
                </li>
              </ul>
            </p>
          </Section>
          <Section title="Places to visit">
            <p>
              <ul className="places-to-visit">
                <li>
                  <b>Mumbai</b> &mdash; A kaleidoscope of contrasts and color,
                  Filisha's home. Dubbed the “City of Dreams” thanks to its
                  endless opportunities, Mumbai is the richest city in India and
                  the center of the country’s financial and film industries.
                  It’s a microcosm of India and yet it is something entirely
                  new: chaotic, colorful, and jam-packed with people from all
                  over the country and the world. Originally a small fishing
                  community, today it’s a city of more than 20 million people,
                  where the dichotomy of India is on full display.
                  Million-dollar apartments look onto slums, while street
                  vendors and hawkers operate mere yards from fancy hotels.
                  Between its beaches, skyline, food, and people, though, it’s
                  impossible to not fall in love with Mumbai. There is a story
                  on every inch of pavement, every stretch of sky, and whatever
                  falls between them. As the saying goes, “Once you have lived
                  in Mumbai, no other city is good enough.”
                </li>

                <li>
                  <b>Goa</b> &mdash;Known for its gorgeous beaches and hippie
                  vibe, this tiny state in western India also offers a
                  fascinating blend of Indian and Portuguese influences that are
                  reflected in its buildings, cuisine and art. Think of it as
                  India’s happy place: The state of Goa, which occupies a sliver
                  on the country’s western coast, is where residents of Mumbai,
                  Delhi, Bangalore, and other cities converge for long weekends
                  and lazy holidays filled with sun, sand and revelry. These
                  days, the beguiling beaches might look like they’ve been
                  colonized by foreign hippies and rave-goers by the thousands,
                  but the region’s European connection dates back centuries. The
                  Portuguese defeated the Adil Shahi dynasty of Bijapur in 1510,
                  beginning a four-and-a-half-century reign — Goa only became
                  part of India in 1961, 14 years after the rest of the country
                  won its independence from Britain. The result of this
                  commingling of cultures is one of the most fascinating pockets
                  of India, defined by a leisurely pace and laid-back attitude.
                  Perhaps there’s no better way to describe the vibe in Goa than
                  “susegaad,” a Konkani term derived from Portuguese that
                  alludes to a sybaritic, contented way of life
                </li>
                <li>
                  <b>Rajasthan</b> &mdash; Here is India's archetypal land of
                  maharajas and medieval forts, palaces and tigers, and
                  kaleidoscopic festivals. Rajasthan really is the jewel in
                  India's crown. Jaipur is the capital and gateway to Rajasthan,
                  and once you've slept in a palace, explored a medieval fort or
                  swayed on a camel, you'll want to experience more. Rajasthan's
                  big-ticket attractions are its magnificent forts and palaces.
                  Powerful forts loom from mountain tops, their battle-scarred
                  ramparts still defying long-dead enemies. Spiked doors that
                  once held war elephants at bay open onto the twisting
                  approaches to the palaces within. Austere and practical give
                  way to fantasy and opulence once safely inside. Carved marble
                  and stone, fountains and colored glass decorate the halls of
                  business and rooms of pleasure. All across Rajasthan there are
                  numerous forgotten forts and lovingly restored palaces,
                  including Jaisalmer's fairy-tale desert outpost, Amber's
                  honey-hued fort-palace and Jodhpur's imposing Mehrangarh, to
                  name just a few.
                </li>
                <li>
                  <b>Kerala</b> &mdash; You will already be in the state of
                  Kerala for the wedding in Kochi. Extending the trip, you can
                  explore more of this state. For many travelers, Kerala is
                  South India's most serenely beautiful state. This slender
                  coastal strip is defined by its layered landscape: almost 373
                  miles (600km) of glorious Arabian Sea coast and beaches; a
                  languid network of glistening backwaters; and the spice- and
                  tea-covered hills of the Western Ghats, dotted with fiercely
                  protected wildlife reserves and cool hill stations such as
                  Munnar. Just setting foot on this swathe of soul-soothing,
                  palm-shaded green will slow your subcontinental stride to a
                  blissed-out amble. Kerala is a world away from the hectic
                  action of the rest of India, its long, fascinating backstory
                  illuminated by historically evocative cities like Kochi
                  (Cochin) and Thiruvananthapuram (Trivandrum). Besides the
                  famous backwaters, elegant houseboats, ayurvedic treatments
                  and delicately spiced, taste-bud-tingling cuisine, Kerala is
                  home to wild elephants, exotic birds and the odd tiger, while
                  vibrant traditions such as Kathakali, theyyam (a
                  trance-induced ritual), temple festivals and snake-boat races
                  frequently bring even the smallest villages to life.
                </li>
              </ul>
            </p>
          </Section>
        </Sections>
      </div>
    </div>
  );
  /* 
  TODO:

  meta: Should this be "travel & logistics" or similar? not JUST for people travelling.

  meta: Do we need an "FAQ"?


  different versions for Indian friends vs USA friends?


  Clothing. What to have, what to buy, how much to spend, styles, recommendations.

  --------------------------------------------------------

  Sea princess hotel in mumbai
      will you come to mumbai and stay here?
      how many days?
      travel from airport is arranged (paid by guest)
  plane from mumbai to kochi
      we have agent to help book
      email this person, including your full name exactly as it appears on your passport, and they will help arrange travel


    Please RSVP by may 31st





  --------------------------------------------------------
  DONE (or partially done)
  --------------------------------------------------------

  flights. source cities, destination cities (Mumbai).
  expected cost ($1000-$1200 if coming from USA). When's a good time to book flights.
  separate section for flights within India for Indian friends?

  buffer time around wedding. Arrive early, leave after. 
  Travel takes full 24 hours from USA, plus jetlag and time difference.
  Suggested Options for durations of stay (5-day trip, 10-day trip, etc)

  Post-wedding travel plan options.
  3 different vibes for what you could do, e.g.  Tropical South India; Rustic Forts of Rajasthan; City hopping Goa, Delhi, Mumbai.

  About Travelling to India
  what to pack
  what to expect


  visa (e-visa)
  COVID
  travel doctor, vaccinations / malaria pills
  eating / food
  warning about passport expiry. Renew your passport now if it will be expire within 6months of travelling!
  info about venue. Pics, location, links.  Should this be in "Events"?

  what's covered by us:
  3 days 2 nights accomodation in Kochi.
  meals during events included.
  all food is vegetarian. Vegan options available. 
  Let us know about other dietary restrictions. (allergies)
  some but not all events have alcohol.

  */
}

function Expandable({
  title,
  children,
  className,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <div className={className}>
      <div
        className="expandable-title"
        role={"button"}
        onClick={() => {
          setIsExpanded((e) => !e);
        }}
      >
        <span className="expandable-disclosure-triangle" style={{}}>
          {isExpanded ? "▼" : "►"}
        </span>
        {title}
      </div>
      {isExpanded ? children : null}
    </div>
  );
}

function SmallUl(
  props: { children: React.ReactNode } & React.HTMLProps<HTMLUListElement>
) {
  return (
    <ul className="reduced-width" {...props}>
      {props.children}
    </ul>
  );
}
