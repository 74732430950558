import React, { ReactNode } from "react";
import { FloralAccent, MDashes, SmallCapsTitle, Subline } from "../components";

const boyGirlSettingChoice = "clothing-choice-boy-or-girl";
function getClothesGirlBoyChoiceFromLocalStorage(): "boy" | "girl" {
  try {
    return (
      (localStorage.getItem(boyGirlSettingChoice) as "boy" | "girl") ?? "boy"
    );
  } catch {}
  return "boy";
}
function setClothesGirlBoyChoiceFromLocalStorage(isBoy: boolean) {
  try {
    localStorage.setItem(boyGirlSettingChoice, isBoy ? "boy" : "girl");
  } catch {}
}

export function Clothes() {
  const [isBoys, setIsBoys] = React.useState(
    getClothesGirlBoyChoiceFromLocalStorage() === "boy"
  );
  const flipBoySwitch = () => {
    const newVal = !isBoys;
    setIsBoys(newVal);
    setClothesGirlBoyChoiceFromLocalStorage(newVal);
  };
  return (
    <div>
      <div style={{ width: "100vw", position: "relative" }}>
        <FloralAccent
          src="/bw3.png"
          style={{ left: "-83px", top: "-110px", right: "unset" }}
          imgStyle={{ maxWidth: "380px" }}
          flip
          scale={0.8}
        />
        <FloralAccent
          src="/bw1.png"
          style={{ top: "-70px", right: "-50px" }}
          imgStyle={{ maxWidth: "380px" }}
          flip
          scale={0.8}
        />
      </div>
      <div className="section section-small">
        <div className="clothes">
          <MDashes>Clothes</MDashes>
          <div className="center-flex clothing-content">
            <SmallCapsTitle>Buying Clothes in Mumbai</SmallCapsTitle>
            <a href="https://goo.gl/maps/xUGdBV3YriSZGT5C6">
              Explore suggested places on the map
            </a>
            <p>
              The Sea Princess hotel is conveniently located near several nice
              Women's clothing stores within walking distance. We recommend any
              of the following for Women's clothes:
              <ul>
                <li>Ritu Kumar</li>
                <li>17:17</li>
                <li>Saundh</li>
                <li>Kahani</li>
                <li>Malmal</li>
              </ul>
            </p>
            <p>
              Men's clothing stores require a short Uber ride, but there's
              several good places to go. We recommend:
              <ul>
                <li>Millionare</li>
                <li>Telon</li>
                <li>Kora</li>
              </ul>
            </p>
            <p>
              There's also several good food options nearby if you're hungry
              after shopping:
              <ul>
                <li>Soho house</li>
                <li>Shiv Saagar</li>
                <li>Grandma's Cafe</li>
                <li>Little Italy</li>
              </ul>
            </p>
            <SmallCapsTitle>Online Recommendations</SmallCapsTitle>
            <p>
              These are the websites we recommend for you to bootstrap your
              outfit search. The shops linked here give a wide range of options
              to suit your individual style and budget. Dressing up is a lot of
              the fun for an Indian wedding but as always, we would like to
              empahsize that we value your presence over all else.
            </p>
            <p>
              For the most trendy and current designer wear:
              <SmallLinksList
                links={["www.ensembleindia.com", "www.perniaspopupshop.com"]}
              />
            </p>
            <p>
              For an affordable one-stop shop for all men's clothes:
              <SmallLinksList links={["www.manyavar.com"]} />
            </p>
            <p>
              For affordables saris (and more) especially for the wedding:
              <SmallLinksList links={["tyaarindia.com"]} />
            </p>
            <p>
              For other wide-range affordable options:
              <SmallLinksList
                links={["www.inddus.com", "www.kalkifashion.com"]}
              />
            </p>

            {/* <p>Dressing up is half of the fun for an Indian wedding!</p>
            <p>
              Both men and women's clothes come in dazzling, bright colors with
              lots of patterns, embellishments, and prints.
            </p> */}
            {/* <MDashes>Types of Clothes</MDashes>
            {isBoys ? (
              <div>
                <TypeOfClothing title="Kurta" imgSrc="kurta.jpg">
                  A <b>Kurta</b> is a type of suit featuring wide-leg,
                  palazzo-style pants and a matching tunic top. The set is
                  typically decorated with sparkly beading, brocade patterns,
                  and embroidery.
                </TypeOfClothing>
              </div>
            ) : (
              <div>
                <TypeOfClothing title="Saree" imgSrc="kurta.jpg">
                  A <b>Saree</b> is an elaborately tied drape of fabric that is
                  worn from the shoulders to the ankles, covering one or both of
                  your arms.
                </TypeOfClothing>

                <TypeOfClothing title="Lehenga">
                  <b>Lehenga</b> is another popular option, but unlike a saree,
                  this is typically a two-piece outfit consisting of a crop top
                  and full skirt, with a long scarf like fabric called a
                  dupatta.
                  <ImageSet>
                    <img src="kurta.jpg" />
                    <img src="kurta.jpg" />
                    <img src="kurta.jpg" />
                  </ImageSet>
                </TypeOfClothing>
                <TypeOfClothing title="Sharara" imgSrc="kurta.jpg">
                  <b>Sharara</b> which is a type of suit featuring wide-leg,
                  palazzo-style pants and a matching tunic top (sometimes called
                  a kurti or kurta). The set is typically decorated with sparkly
                  beading, brocade patterns, and embroidery.
                </TypeOfClothing>
              </div>
            )} */}
            {/* <MDashes>Where to buys clothes</MDashes>
            <p>
              If you live in an area with a large Indian-American population,
              try a local Indian, Pakistani, or Nepalese market, which will
              likely sell clothing.
            </p>
            <p>
              There are also plenty of online shops, such as:
              <ul>
                <li>
                  <a href="https://indiaspopup.com/">indiaspopup</a>
                </li>
              </ul>
            </p>
            <p>
              You can also buy clothes in India, depending on how long ahead of
              time you'll arrive and how decisive you are.
            </p>
            <SmallCapsTitle></SmallCapsTitle>

            <MDashes>The Community Wardrobe™</MDashes>
            <p>
              We believe in sustainability and celebrating the stories of each
              piece of clothing. We're starting something we call{" "}
              <i>The Community Wardrobe</i>&mdash;a way to share and re-use
              clothes.
            </p>
            <p>
              Many of our guests are coming from the United States and don't
              have any indian clothes at all, while many of our guests are from
              India and have plenty of previously-worn outfits to spare! We want
              to connect these groups and let people them share and appreciate
              the clothes that others may have.
            </p>
            <p>
              Unfortunately, it'll be hard to guarantee that there will be
              enough clothes that fit and match people's aesthetics. So the
              safest bet will always be to find your own clothes ahead of time,
              or only use the Community Wardrobe if you arrive in Mumbai ahead
              of time.
            </p>
            <p>Interesting in participating? Let us know!</p>
            <div>
              ✅ I want to provide my Indian clothes to the Community Wardrobe
            </div>
            <div>✅ I want to try clothing from the Community Wardrobe</div>
            <SmallCapsTitle></SmallCapsTitle> */}
            <MDashes>Suggestions for Events</MDashes>
            <EventClothesInfo>
              <SmallCapsTitle>Mehendi</SmallCapsTitle>
              <Subline>Suggested Attire: Indian Artisan</Subline>
              <PintrestLink href="https://pin.it/2RtqqaA">
                See all Mehendi inspiration pictures here
              </PintrestLink>

              <ImageCarosel2>
                <img src="/clothesPics/mehendi/m1.jpg" />
                <img src="/clothesPics/mehendi/m2.jpg" />
                <img
                  src="/clothesPics/mehendi/m3.jpg"
                  style={{ objectPosition: "35% center" }}
                />
                <img src="/clothesPics/mehendi/m4.jpg" />
                <img src="/clothesPics/mehendi/m5.png" />
              </ImageCarosel2>
            </EventClothesInfo>
            <EventClothesInfo>
              <SmallCapsTitle>Sangh wali Sangeet</SmallCapsTitle>
              <Subline>Suggested Attire: Glam Night Formal</Subline>
              <PintrestLink href="https://pin.it/2MDqhD6">
                See all Sangeet inspiration pictures here
              </PintrestLink>
              <ImageCarosel2>
                <img src="/clothesPics/sangeet/s1.jpg" />
                <img src="/clothesPics/sangeet/s2.jpg" />
                <img src="/clothesPics/sangeet/s3.jpg" />
                <img src="/clothesPics/sangeet/s4.jpg" />
                <img src="/clothesPics/sangeet/s5.jpg" />
              </ImageCarosel2>
            </EventClothesInfo>
            <EventClothesInfo>
              <SmallCapsTitle>Brunch &amp; Vows</SmallCapsTitle>
              <Subline>Suggested Attire: Resort Formal</Subline>
              <PintrestLink href="https://pin.it/4o3N8eD">
                See all Brunch &amp; Vows inspiration pictures here
              </PintrestLink>
              <ImageCarosel2>
                <img src="/clothesPics/brunch/b1.jpg" />
                <img src="/clothesPics/brunch/b2.jpg" />
                <img src="/clothesPics/brunch/b3.jpg" />
                <img src="/clothesPics/brunch/b4.jpg" />
                <img src="/clothesPics/brunch/b5.png" />
              </ImageCarosel2>
            </EventClothesInfo>
            <EventClothesInfo>
              <SmallCapsTitle>Wedding Ceremony</SmallCapsTitle>
              <Subline>Suggested Attire: Indian Traditional</Subline>
              <PintrestLink href="https://pin.it/ELPaTTy">
                See all Wedding inspiration pictures here
              </PintrestLink>
              <ImageCarosel2>
                <img src="/clothesPics/wedding/w2.jpg" />
                <img
                  src="/clothesPics/wedding/w3.png"
                  style={{ objectPosition: "25% center" }}
                />
                <img src="/clothesPics/wedding/w4.png" />
                <img src="/clothesPics/wedding/w1.png" />
                <img src="/clothesPics/wedding/w5.jpg" />
              </ImageCarosel2>
            </EventClothesInfo>
          </div>
        </div>
      </div>
    </div>
  );
}

function EventClothesInfo({ children }: { children: React.ReactNode }) {
  return <div className="event-clothes-info">{children}</div>;
}

function TypeOfClothing({
  title,
  imgSrc,
  children,
}: {
  title: string;
  imgSrc?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="typeOfClothing">
      <SmallCapsTitle>{title}</SmallCapsTitle>
      {imgSrc ? (
        <div className="leftright">
          <img src={imgSrc} />
          <div>
            <p>{children}</p>
          </div>
        </div>
      ) : (
        <div className="leftright">
          <p>{children}</p>
        </div>
      )}
    </div>
  );
}

function PintrestLink({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  return (
    <b>
      <br />
      <a href={href}>{children}</a>
    </b>
  );
}

function ImageSet({ children }: { children: React.ReactNode }) {
  return <div className="imageset">{children}</div>;
}

function ImageCarosel({ children }: { children: Array<React.ReactNode> }) {
  return (
    <div className="image-carosel">
      <div className="image-carosel-inner">
        {children.map((child, i) => (
          <div className="carosel-item" key={i}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
}

function ImageCarosel2({ children }: { children: Array<React.ReactNode> }) {
  return (
    <div className="image-carosel2">
      <div className="image-carosel2-inner">
        {children.map((child, i) => (
          <div className="carosel2-item" key={i}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
}

function SmallLinksList({ links }: { links: Array<string> }) {
  return (
    <div className="small-links-list">
      <ul>
        {links.map((link) => (
          <li key={link}>
            <a href={`https://${link}`}>{link}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
